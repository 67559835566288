import React, { useEffect, useState } from "react";
import "./homepage.css";
import Header from './header'; // Adjust the path based on the location of your Header.js file
import Footer from './Footer'; // Import the Footer component
import Modal from "./Modal"; // Modal component
import ContactForm from "./ContactForm"; // ContactForm component
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const scrollToServices = () => {
  const servicesSection = document.getElementById("services");
  if (servicesSection) {
    servicesSection.scrollIntoView({ behavior: "smooth" });
  }
};
const LegalServicesSection = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const section = document.getElementById("legal-services");
      if (section) {
        const top = section.getBoundingClientRect().top;
        if (top < window.innerHeight - 100) {
          setIsVisible(true);
        }
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  },)};
const Homepage = () => {
  const [activeTab, setActiveTab] = useState("tech");  // Corrected useState hook initialization
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  const [isVisible, setIsVisible] = useState(false);

  const [subject, setSubject] = useState('');
  const isSubjectEditable = true; // You can manage this prop dynamically
  useEffect(() => {
    const sections = document.querySelectorAll(".scroll-section");
    setIsVisible(true);

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("scroll-visible");
          }
        });
      },
      { threshold: 0.1 }
    );

    sections.forEach((section) => observer.observe(section));

    return () => {
      sections.forEach((section) => observer.unobserve(section));
    };
  }, []);
  const handleModalToggle = (heading, label) => {
    setIsModalOpen(!isModalOpen); // Toggle modal visibility
    setSubject(`${heading}: ${label}`); // Set subject as heading and label with colon separator
  };
  
  return (
    <div>
      <Header /> {/* Add the Header component */}
      <main>
        <div className="homepage">
          {/* Hero Section */}
          <section className="hero">
            <div className="hero-content">
              <div className="hero-text">
                <h1>Legal Engineering</h1>
                <p>Revolutionalizing Law Firms &</p>

                <p>Legally dedicated to budding entrepreneurs, tech adopters & digital creators.</p>

                <div className="hero-buttons">
                  <button className="primary-button" onClick={scrollToServices}>
                    Explore Services
                  </button>
                </div>
              </div>
            </div>
          </section>



      {/* About Us Section */}
      <section className="about scroll-section">
        <h2>About Us</h2>
        <p>
          With over a decade of combined experience across diverse fields, our team provides tailored legal software powered  by AI to streamline legal decision. We help legal entities go digital as well through scalable systems.
        </p>

      </section>

      <section id="legal-services" className="legal-section">
      {/* Background Video */}
      <video className="background-video" autoPlay loop muted playsInline>
        <source src="/maybe.webm" type="video/webm" />
        Your browser does not support the video tag.
      </video>

      {/* Overlay to ensure readability */}
      <div className="overlay">
        <div className="cards-container">
          {/* Card 1: Take Your Law Firm Digital (Left) */}
          <motion.div
            className="legal-card left-card"
            initial={{ opacity: 0, x: -50 }}
            animate={isVisible ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 0.8 }}
          >
            <h2>Take Your Law Firm Digital</h2>
            <p>Automate your law practice.</p>
            <button className="cta-button">Get Started</button>
          </motion.div>

          {/* Card 2: Try Out Our Tools (Right) */}
          <motion.div
            className="legal-card right-card"
            initial={{ opacity: 0, x: 50 }}
            animate={isVisible ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 0.8, delay: 0.3 }}
          >
            <h2>Review your Contract</h2>
            <p>Use AI to enter into contracts safely</p>
            <button className="cta-button">Get Started</button>
          </motion.div>


                    {/* Card 2: Try Out Our Tools (Right) */}
                    <motion.div
            className="legal-card third-card"
            initial={{ opacity: 0, x: 50 }}
            animate={isVisible ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 0.8, delay: 0.3 }}
          >
            <h2>Try Out Our Chatbot</h2>
            <p>Use Teresya to obtain legal insight</p>
            <button className="cta-button">Get Started</button>
          </motion.div>

                              {/* Card 2: Try Out Our Tools (Right) */}
                              <motion.div
            className="legal-card fourth-card"
            initial={{ opacity: 0, x: 50 }}
            animate={isVisible ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 0.8, delay: 0.3 }}
          >
            <h2>Draft a Document</h2>
            <p>Automatically generate legal documents</p>
            <button className="cta-button">Get Started</button>
          </motion.div>
        </div>
      </div>
    </section>




{/* Client Profiles Section */}
<section id="client-profiles" className="industries-section">




  
  {/* Free Consultation Section */}
  <div className="free-consultation-card">
    <h3>Ready to Transform Your Business?</h3>
    <p>Leverage our legal tools</p>
    <button
  className="action-card-white-button"
  onClick={() => handleModalToggle("Schedule", "Discovery")}
>
  Schedule a Discovery
</button>

            {/* Modal with ContactForm as child */}
            <Modal isOpen={isModalOpen} onClose={handleModalToggle}>
        <ContactForm
          subject={subject}
          setSubject={setSubject}
          isSubjectEditable={isSubjectEditable}
        />
      </Modal>

  </div>
</section>


{/* Legal Engineering Testimonials Section */}
<section id="legal-engineering-testimonials" className="testimonials-section">
  <h2>Legal Engineering Success Stories</h2>
  <div className="testimonials-wrapper">
    <div className="testimonial-card">
      <p className="testimonial-date">Testimonial on Dec 9, 2024</p>
      <h3>Automated Business Structuring</h3>
      <p className="testimonial-category">Legal Engineering Solutions</p>
      <p className="testimonial-author">Juan Diego Rodriguez</p>
      <p>"Their AI-powered tools streamlined our corporate structuring and automated compliance workflows. The precision in legal automation saved us valuable time and ensured accuracy."</p>
    </div>
    <div className="testimonial-card">
      <p className="testimonial-date">Testimonial on Dec 4, 2024</p>
      <h3>AI-Driven IP Protection</h3>
      <p className="testimonial-category">Legal Tech Innovation</p>
      <p className="testimonial-author">Geoff Graham</p>
      <p>"Their legal AI solution provided unparalleled insights into intellectual property protection. From automated trademark checks to patent strategy, their technology reshaped our approach to IP security."</p>
    </div>
    <div className="testimonial-card">
      <p className="testimonial-date">Testimonial on Dec 12, 2024</p>
      <h3>Regulatory Compliance Automation</h3>
      <p className="testimonial-category">Legal Engineering | Compliance Tech</p>
      <p className="testimonial-author">Daniel Schwarz</p>
      <p>"Their compliance automation system simplified our regulatory requirements, integrating seamlessly with our operations. We now navigate legal complexities with confidence and efficiency."</p>
    </div>
    <div className="testimonial-card">
      <p className="testimonial-date">Testimonial on Oct 29, 2024</p>
      <h3>Global Legal Expansion Tools</h3>
      <p className="testimonial-category">Legal Engineering | Cross-Border Solutions</p>
      <p className="testimonial-author">Sara Joy</p>
      <p>"Their AI-assisted legal framework provided us with clear international compliance strategies, making our global expansion seamless and legally sound."</p>
    </div>
    <div className="testimonial-card">
      <p className="testimonial-date">Testimonial on Oct 29, 2024</p>
      <h3>Smart Contract Automation</h3>
      <p className="testimonial-category">Legal Engineering | Blockchain Contracts</p>
      <p className="testimonial-author">Zell Liew</p>
      <p>"Their smart contract automation tools revolutionized how we handle agreements, ensuring security, efficiency, and legal enforceability at scale."</p>
    </div>
  </div>
</section>


<section class="trending-topics">
<div class="shape shape-one"></div>
<div class="shape shape-two"></div>
  <h2>Trending topics</h2>
  <a href="/BlogsPage" class="more-topics">MORE TRENDING TOPICS →</a>

  <div class="topic-container">
    <div class="topic">
      <h3>Generative AI</h3>
    </div>
    <div class="topic">
      <h3>Environmental, Social & Governance</h3>
    </div>
    <div class="topic">
      <h3>AdTech</h3>
    </div>
  </div>

  <div class="stay-updated">
    <h3>Stay Updated</h3>
    <p>Subscribe to get the latest updates directly in your inbox:</p>
    <form class="email-subscription">
      <input 
        type="email" 
        placeholder="Enter your email" 
        aria-label="Enter your email to stay updated" 
        required 
      />
      <button type="submit">Subscribe</button>
    </form>
  </div>
</section>

          <Footer /> {/* Add the Footer component */}
        </div>
      </main>
    </div>
  );
};

export default Homepage;
