import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com"; // Import the EmailJS SDK
import './getinvolved.css'; // Assuming your CSS file is named Chatbot.css
import Header from './header';
import Footer from './Footer';
const Chatbot = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    resumeLink: "", // Store resume link
    partnershipType: "",
  });
  const [userMessage, setUserMessage] = useState("");
  const [chatMessages, setChatMessages] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [chatVisible, setChatVisible] = useState(false); // Control chatbot visibility
  const [thankYouVisible, setThankYouVisible] = useState(false); // Show thank you message after submission
  const [confirmSubmit, setConfirmSubmit] = useState(false); // Confirm before submission

  const conversation = [
    { step: 1, question: "What is your full name?" }, // Start with the name question
    { step: 2, question: "What type of partnership are you interested in?" },
    { step: 3, question: "What is your email address?" },
    { step: 4, question: "Please provide the link to your resume or LinkedIn profile." },
    { step: 5, question: "Thank you! Your details have been submitted." }
  ];

  useEffect(() => {
    if (chatVisible) {
      addMessage(conversation[0]?.question, "bot");
    }
  }, [chatVisible]);

  const addMessage = (message, sender = "bot") => {
    setChatMessages((prevMessages) => [
      ...prevMessages,
      { sender, message },
    ]);
  };

  const handleNextStep = (inputValue) => {
    if (currentStep === 1) {
      if (!inputValue) {
        setErrorMessage("Name is required.");
        return;
      }
      setFormData((prevData) => ({ ...prevData, name: inputValue }));
    } else if (currentStep === 2) {
      if (!inputValue) {
        setErrorMessage("Partnership type is required.");
        return;
      }
      setFormData((prevData) => ({ ...prevData, partnershipType: inputValue }));
    } else if (currentStep === 3) {
      if (!inputValue || !/\S+@\S+\.\S+/.test(inputValue)) {
        setErrorMessage("Valid email is required.");
        return;
      }
      setFormData((prevData) => ({ ...prevData, email: inputValue }));
    } else if (currentStep === 4) {
      if (!inputValue || !/^https?:\/\/\S+$/.test(inputValue)) { // Validate the URL
        setErrorMessage("Please provide a valid link to your resume or LinkedIn.");
        return;
      }
      setFormData((prevData) => ({ ...prevData, resumeLink: inputValue }));
      setConfirmSubmit(true); // Set confirmation before final submission
    } else if (currentStep === 5) {
      sendEmail();
      setThankYouVisible(true); // Show the "Thank you" message
      setChatVisible(false); // Hide the chatbot after submission
      return;
    }

    setErrorMessage("");
    setCurrentStep((prevStep) => prevStep + 1);
    if (conversation[currentStep]) {
      addMessage(conversation[currentStep]?.question);
    }
  };

  const handleSubmit = () => {
    if (userMessage.trim()) {
      addMessage(userMessage, "user");
      setUserMessage("");
      handleNextStep(userMessage);
    }
  };

  const sendEmail = () => {
    const templateParams = {
      name: formData.name,
      email: formData.email,
      resumeLink: formData.resumeLink, // Include resume link in email
      partnershipType: formData.partnershipType,
    };

    emailjs
      .send(
        "service_ohp6vf9",
        "template_cblkn58",
        templateParams,
        "inqqXvUoU7veLS_S-"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
        },
        (error) => {
          console.error("FAILED...", error);
        }
      );
  };

  const startChatbot = () => {
    setChatVisible(true); // Show the chatbot when the button is clicked
  };

  const handleConfirmSubmit = () => {
    // Confirm submission and send email
    handleNextStep(""); // Proceed to final step
    setConfirmSubmit(false); // Reset the confirmation flag
  };

  return (

    <div className="page-container">
          <div>
            <Header />

    </div>
      <div className="opportunities">
        <h2>We are on the lookout for partners in the fields of law and technology. These partnerships can come in for of :</h2>
        <p>1. Individuals - AI engineers and developers who can collaborate on innovative projects.</p>
        <p>2. Community - Enthusiasts of technology law for pro-bono educational initiatives.</p>

        <button className="opportunity-button" onClick={startChatbot}>
          Partner with us
        </button>
      </div>

      {chatVisible && ( // Conditionally render the chatbot only when chatVisible is true
        <div className="chatbot-container">
          <div className="chat-container" id="chat-container">
            {chatMessages.map((msg, index) => (
              <div key={index} className={`message ${msg.sender}`}>
                {msg.message}
              </div>
            ))}
          </div>

          <div className="chat-input-container">
            {currentStep === 1 && (
              <input
                type="text"
                value={userMessage}
                onChange={(e) => setUserMessage(e.target.value)}
                placeholder="Enter your full name"
              />
            )}

            {currentStep === 2 && (
              <select
                value={userMessage}
                onChange={(e) => setUserMessage(e.target.value)}
              >
                <option value="">Select partnership type</option>
                <option value="Legal">Legal</option>
                <option value="AI">AI</option>
                <option value="Technology">Technology</option>
                <option value="Other">Other</option>
              </select>
            )}

            {currentStep === 3 && (
              <input
                type="email"
                value={userMessage}
                onChange={(e) => setUserMessage(e.target.value)}
                placeholder="Enter your email address"
              />
            )}

            {currentStep === 4 && (
              <input
                type="text"
                value={userMessage}
                onChange={(e) => setUserMessage(e.target.value)}
                placeholder="Paste your resume or LinkedIn link"
              />
            )}

            {currentStep === 5 && !thankYouVisible && (
              <div>Your details have been submitted. Thank you!</div>
            )}

            {/* Only show confirmation on the last step */}
            {confirmSubmit && currentStep === 4 && (
              <div>
                <p>Are you sure you want to submit your details?</p>
                <button onClick={handleConfirmSubmit}>Yes, submit</button>
                <button onClick={() => setChatVisible(false)}>No, cancel</button>
              </div>
            )}

            {/* Hide the Send button on the last step */}
            {currentStep < 5 && !confirmSubmit && (
              <button id="chat-submit" onClick={handleSubmit}>
                Send
              </button>
            )}
          </div>

          {errorMessage && <div className="error-message">{errorMessage}</div>}
        </div>
      )}

      {thankYouVisible && (
        <div className="thank-you-message">
          <h3>Thank you! Your details have been submitted.</h3>
        </div>
      )}
      
    </div>
    
  );

};

export default Chatbot;
