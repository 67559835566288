import React from 'react';
import './Footer.css'; // Import the CSS file for styling

const Footer = () => {
  // Get the current year dynamically
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h3>ELP</h3>
          <p>We aim to democratize legal services through tech</p>
          <div className="social-icons">
            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
              <i className="fa fa-linkedin"></i>
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
              <i className="fa fa-twitter"></i>
            </a>
          </div>
        </div>
        <div className="footer-section">
          <h3>Quick Links</h3>
          <ul>

          </ul>
        </div>
        <div className="footer-section">
          <h3>Get In Touch</h3>
          <p>Email: <a href="mailto:entr.legal.partner@gmail.com">info@entrepreneuriallegalpartner.com</a></p>
          <p>Location: Nairobi, Kenya</p>
        </div>
      </div>
      <div className="footer-bottom">
        <p>© {currentYear} Entrepreneurial Legal Partner. All rights reserved.</p>
        <a href="/PrivacyPolicy">ELP Privacy Policy</a>
      </div>
    </footer>
  );
}

export default Footer;
